<template>
  <el-main>
    <div class="topTotal">
      <div class="infoBox">
        <div>
          收益总金额
          <el-tooltip content="筛选后收益金额" placement="top-start" effect="light">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="money">￥{{ Number(totalMoney).toFixed(2) }}</div>
      </div>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="订单编号：">
        <el-input
          size="small"
          v-model="searchItem.orderNum"
          placeholder="请输入订单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="交易时间：">
        <el-date-picker
          v-model="searchItem.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          @change="timeChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="店铺名称：">
        <el-input
          size="small"
          v-model="searchItem.shopName"
          placeholder="请输入店铺名称："
        ></el-input>
      </el-form-item>
      <el-form-item label="用户信息：">
        <el-input
          size="small"
          v-model="searchItem.orderInfo"
          placeholder="请输入用户信息"
        ></el-input>
      </el-form-item>
      <el-form-item label="所属地区：">
        <el-select v-model="searchItem.cityId" filterable size="small" placeholder="请选择">
          <el-option
            v-for="item in cityList"
            :key="item.city_id"
            :label="item.city_name"
            :value="item.city_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="transactionTime" label="交易时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.pay_time) }}</template>
      </el-table-column>
      <el-table-column prop="ordersn" label="订单号" align="center"> </el-table-column>
      <el-table-column label="用户信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nickname" :avatar="row.member_avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column label="所属店铺" align="center">
        <template v-slot="{ row }">{{ row.name }}</template>
      </el-table-column>
      <el-table-column label="所属区域" align="center">
        <template v-slot="{ row }">{{ row.city_name || '--' }}</template> </el-table-column
      ><el-table-column label="收益金额" align="center">
        <template v-slot="{ row }"> ￥{{ row.price }} </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging'
import UserInfo from '@/components/userInfo'
import { getDateformat } from '@/util/getDate.js'
export default {
  components: {
    Paging,
    UserInfo
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        orderNum: '',
        orderInfo: '',
        time: [],
        shopName: null,
        cityId: 0
      },
      cityList: [{ city_name: '全部', city_id: 0 }],
      dataList: [],
      page: 1,
      rows: 10,
      total: 0,
      totalMoney: 0
    }
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getShopList();
    },
    cancelSearch() {
      this.page = 1
      this.searchItem = {
        orderNum: '',
        orderInfo: '',
        time: [],
        shopName: null,
        cityId: 0
      }
      this.getShopList()
    },
    getShopList(style) {
      if (style) {
        this.page = 1
      }
      let obj = {
        page: this.page,
        rows: this.rows
      }
      let time = this.searchItem.time
      if (time.length) {
        // 如果需要按时间查询
        obj.start_time = Math.ceil(time[0].getTime() / 1000)
        obj.end_time = Math.ceil(time[1].getTime() / 1000)
        if (obj.start_time == obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1
        }
      }
      if (this.searchItem.orderNum) {
        obj.ordersn = this.searchItem.orderNum
      }
      if (this.searchItem.shopName) {
        obj.case_name = this.searchItem.shopName
      }
      if (this.searchItem.orderInfo) {
        obj.name = this.searchItem.orderInfo
      }
      if (this.searchItem.cityId) {
        obj.city_id = this.searchItem.cityId
      }
      this.$axios.post(this.$api.samecity.SameCityAdList, obj).then((res) => {
        if (res.code === 0) {
          this.dataList = res.result.list
          this.total = res.result.total
          this.totalMoney = res.result.total_price
        }
      })
    },
    getCity() {
      this.$axios.post(this.$api.samecity.selectCityList).then((res) => {
        if (res.code == 0) {
          this.cityList = this.cityList.concat(res.result.list)
        }
      })
    }
  },
  created() {
    this.getCity()
    this.getShopList()
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .topTotal {
    width: 100%;
    background-color: #f8f9fa;
    height: 200px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .infoBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
    }
    .money {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  .bot {
    h1 {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
